<script setup lang="ts">
import type { UIZoomableImageProps } from './UIZoomableImage.props'

const props = defineProps<UIZoomableImageProps>()

const zoomLevel = 2.5
const imageStyle = ref({})
const isZoomEnabled = ref(false)

const emit = defineEmits<{
  toggleZoom: [isZoomEnabled: boolean]
}>()

// Calculate cursor position over the image and apply zoom effect with cursor as focal point
const handleMouseMove = (event: MouseEvent) => {
  if (!isZoomEnabled.value) return
  const target = event.currentTarget as HTMLElement
  const { width, height, left, top } = target.getBoundingClientRect()
  const x = ((event.clientX - left) / width) * 100
  const y = ((event.clientY - top) / height) * 100

  imageStyle.value = {
    transform: `scale(${zoomLevel})`,
    transformOrigin: `${x}% ${y}%`,
  }
}

// Calculate touch position over the image and apply zoom effect with touch as focal point
const touchMove = (event: TouchEvent) => {
  if (!isZoomEnabled.value) return

  const target = event.currentTarget as HTMLElement
  const rect = target.getBoundingClientRect()
  const touch = event.touches[0]

  const x = ((touch.clientX - rect.left) / rect.width) * 100
  const y = ((touch.clientY - rect.top) / rect.height) * 100

  imageStyle.value = {
    transform: `scale(${zoomLevel})`,
    transformOrigin: `${x}% ${y}%`,
    background: '#FBFBFB',
  }
}

// Toggle zoom enabled and emit event
const toggleZoom = () => {
  emit('toggleZoom', isZoomEnabled.value)
  isZoomEnabled.value = !isZoomEnabled.value
  if (!isZoomEnabled.value) {
    imageStyle.value = {}
  } else {
    imageStyle.value = {
      transform: `scale(${zoomLevel})`,
    }
  }
}

// Set image sizes when fullSize is disabled
const sizes = computed(() => {
  if (!props.fullSize) {
    return 'sm:100vw md:100vw lg:50vw'
  }
})

watch(
  () => props.resetZoom,
  () => {
    imageStyle.value = {}
    isZoomEnabled.value = false
  }
)
</script>
<template>
  <div
    class="zoomable-image-wrapper relative flex w-[100vw] items-center justify-center overflow-hidden md:h-[100dvh]"
    @mousemove="handleMouseMove"
  >
    <div class="inline-block">
      <NuxtImg
        :src="src"
        :alt="alt"
        :style="imageStyle"
        tabindex="0"
        provider="cloudinary"
        quality="100"
        class="z-[9000] h-[100dvh] w-full object-contain transition-transform duration-300"
        :class="{
          'custom-zoom-cursor-add': !isZoomEnabled,
          'custom-zoom-cursor-remove': isZoomEnabled,
          'aspect-pdp-image': !title && !aspectRatio,
          [`aspect-${aspectRatio}`]: aspectRatio,
          [title ? 'h-[90dvh]' : 'h-[100dvh]']: true,
          'h-[85dvh]': isGallerySlideShow,
        }"
        :sizes="sizes"
        loading="lazy"
        @click="toggleZoom"
        @keydown.enter="toggleZoom"
        @touchmove="touchMove"
      />
      <span
        v-if="title"
        class="flex h-[10dvh] items-center justify-end lg:px-0"
      >
        {{ title }}
      </span>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.custom-zoom-cursor-add {
  cursor:
    url('@design-system/icons/AddWBkg.svg') 24 24,
    auto;
}
.custom-zoom-cursor-remove {
  cursor:
    url('@design-system/icons/RemoveWBkg.svg') 24 24,
    auto;
}
</style>
